import Layout from "../components/layout";
import Helmet from "react-helmet";
import Header from "../components/header";
import React from "react";
import {graphql} from "gatsby";
import ReactMarkdown from "react-markdown";

const AngebotPage = ({data}) => {
    let angebotPageData = data.allStrapiAngebotPages.edges[0].node;
    let offersData = data.allStrapiOfferPages.edges;
    return (
        <Layout page="angebote">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Voice- und Chatbot Angebote | Paixon</title>
                <meta name="description"
                      content="Unser Angebotsspektrum umfasst die Strategieplanung, Konzeption sowie Umsetzung von Website Chatbots, Sprachassistenten und Messenger Bots."/>
            </Helmet>
            <Header className="offer" slogan={angebotPageData.slogan}
                    blogphoto={{backgroundImage: `url(${angebotPageData.photo.publicURL})`}}
                    offersData={offersData}/>
            <main className="container offer pt">
                <div className="offerOptions">
                    <ReactMarkdown className="offerOptionsIntroduction"
                                   children={angebotPageData.cards_section}/>
                    <section>
                        <div className="row offersCardSection">
                            {data.allStrapiOfferPages.edges.filter(edge => edge.node['show_as_card'] === 'yes').sort((a, b) => a.node.order - b.node.order).map(edge => {
                                const offers = edge.node;
                                const description = offers.description.split('*').filter(text => text.length > 0);
                                return (
                                    <div className="col-md-4 col-sm-4 col-xs-12">
                                        <div className="offercolumn homeOffer">
                                            <div className="offersection-picture"
                                                 style={{backgroundImage: `url(${offers.card_image.publicURL})`}}>
                                            </div>
                                            <div className="offersection-info">
                                                <h3 className="offersection-info-title">{offers.title}</h3>
                                                <ul className="offersection-description-list">
                                                    {description.map(text => {
                                                        return (
                                                            <li className="offersection-description-item">
                                                                {text.trim()}</li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                            <a className="offercolumn-link-button"
                                               href={`${offers.url}`}>
                                                <div className='offercolumn-link-text'>Mehr erfahren</div>
                                            </a>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </section>
                </div>

                <div className="section-white content angebotContent">
                    <ReactMarkdown children={angebotPageData.content}/>
                    <div className="row">
                        <div className="col-xs-4 angebotImage">
                            <img src={angebotPageData.content_photo.publicURL} alt="Unser Angebot in Schritten"
                                 title="Unser Angebot in Schritten"/>
                        </div>
                        <div className="col-xs-8 angebotSteps">
                            <ReactMarkdown children={angebotPageData.content_steps}/>
                        </div>
                    </div>
                </div>

                <div>
                    {angebotPageData.chatbot_workshop && (
                        <div className='section-white offer-call-to-action'>
                            <ReactMarkdown children={angebotPageData.chatbot_workshop}/>
                            <div className="offer-call-to-action-button action-button-element">
                                <a className="action-button"
                                   href="/angebot/chatbot-workshop">
                                    <h3 className="action-button-text">Mehr erfahren</h3>
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </main>
        </Layout>
    )
}

export default AngebotPage


export const pageQuery = graphql`  
query AngebotPageQuery {
    allStrapiOfferPages {
        edges {
            node {
                title
                url
                description
                card_image {
                    publicURL
                }
                show_as_card
                order
            }
        }
    }
    allStrapiAngebotPages {
        edges {
            node {
                content
                content_steps
                cards_section
                slogan
                content_photo {
                    publicURL
                }
                chatbot_workshop
                photo {
                    publicURL
                }
            }
        }
    }
}`
